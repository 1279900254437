/** @jsx jsx */

import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { FC } from 'react'
import { Box, jsx, Container, Text, Flex, Image, Avatar } from 'theme-ui'

import Halo from '../components/halo'
import Intro from '../components/intro'
import Layout from '../components/layout'
import Navigation from '../components/navig'

export interface ProfileProps {
  data: any
}

interface TeamItemProps {
  name?: string
  role?: string
  id?: string
  team?: string
  _rawBio?: any
  image?: any
}
/**
 * Team Item
 * @param {*} members
 */
const TeamItem = ({ name, role, id, team, _rawBio, image }: TeamItemProps) => (
  <Box variant="box.cardBlock">
    <Flex>
      <Box>
        <Avatar width={image.asset.fixed.width} height={image.asset.fixed.height} src={image.asset.fixed.src} />
      </Box>
      <Box mx={4} mt={[0, 1, 5]}>
        <Link to={`/profile/${id}`}>
          <Text variant="text.blockName">{name}</Text>
        </Link>
        <Text variant="text.personName">{role}</Text>
        {/* <Text mt={3} sx={{ fontSize: 1 }}>
          {_rawBio[0].children[0].text}
        </Text> */}
      </Box>
    </Flex>
  </Box>
)

export const AboutSidebar = () => {
  return (
    <Box sx={{ pl: 0, pr: 5, minWidth: '17ch', pt: 6 }}>
      <Link to="/about/">
        <Text as="h2" sx={{ fontWeight: 700, borderBottom: 'solid 1px', borderColor: 'grays.4', pb: 3, mb: 3 }} variant="personName">
          About
        </Text>
      </Link>
      <Link to="/about/team">
        <Text as="h2" sx={{ borderBottom: 'solid 1px #eee', pb: 3, mb: 3 }} variant="personName">
          Team
        </Text>
      </Link>
      <Link to="/about/brand">
        <Text as="h2" variant="personName">
          Brand
        </Text>
      </Link>
    </Box>
  )
}

const TeamMember = (props: any) => (
  <Flex sx={{ mr: 4, width: '45%', bg: 'grays.1', mb: 4 }}>
    <Image
      src={props.image.asset.fixed.src}
      width={props.image.asset.fixed.width}
      height={props.image.asset.fixed.height}
      sx={{ maxWidth: '100%', width: 'auto', mr: 4 }}
    />
    <Box sx={{ pt: 6, pl: 2 }}>
      <Text as="h2" variant="personName" sx={{ fontSize: 2, fontWeight: 'heading', mb: 0, pb: 0 }}>
        {props.name}
      </Text>
      <Text as="p" variant="personBio" sx={{ fontSize: 1, fontWeight: 'heading', color: 'grays.6' }}>
        {props.role}
      </Text>
    </Box>
  </Flex>
)

const Profile: FC<ProfileProps> = ({ data }) => {
  const postEdges = (data && data.profiles && data.profiles.edges) || []

  return (
    <Layout>
      <Navigation />
      <Box variant="box.plateBig" sx={{ px: 4, bg: 'grays.1' }}>
        <Halo title="About" url="https://functionary.co/team" />
        <Container>
          <Box>
            <Flex variant="box.plateMedium" sx={{ color: 'text', pt: 7, pb: 6 }}>
              <AboutSidebar />
              <Box sx={{ pl: [0, 0, 3], pb: 4, pt: 7, pr: 4 }}>
                <Intro title="About Functionary" description="" />
                <Text as="h3" mb={1} variant="blockTitleCentre" sx={{ fontWeight: 'thin' }}>
                  Born out of a fascination towards innovative design and technology, Functionary is a brand that exists
                  to solve real, impactful problems with design and technology. Founded formally in 2018 and based in
                  Bangalore, the name ‘Functionary’ is a reflection of our quest to build innovative world-class
                  products that excel in both ‘Form’ and ‘Function’. We are Functionaries to makers on a mission.
                </Text>
                <Text sx={{ fontSize: 4, py: 5 }}>Our Team</Text>
                <Flex mx={0} sx={{ py: 0, flexWrap: 'wrap' }}>
                  {postEdges && postEdges.length > 0 && postEdges.map((node: any) => <TeamMember {...node.node} />)}
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}

export default Profile

export const pageQuery = graphql`
  query TeamPageQuery {
    profiles: allSanityAuthor(filter: { slug: { current: { ne: null } } }) {
      edges {
        node {
          id
          name
          image {
            asset {
              fixed(width: 180, height: 180) {
                width
                height
                src
              }
            }
          }
          role
          _rawBio
          slug {
            current
          }
        }
      }
    }
  }
`
